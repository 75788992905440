module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-try-ghost/gatsby-browser.js'),
      options: {"plugins":[],"ghostConfig":null,"siteConfig":{"siteUrl":"https://altefide.com","infiniteScroll":true,"postsPerPage":3,"siteTitleMeta":"Jacob's Blog","siteDescriptionMeta":"Deep dive into everyday Life","shortTitle":"Jacob's Blog","siteIcon":"favicon.png","backgroundColor":"#e9e9e9","themeColor":"#15171A","verbose":false,"severity":"info"},"mediaConfig":{"gatsbyImages":true,"gatsbyImageLoading":"lazy","gatsbyImageFadeIn":true},"routes":{"basePath":"/","collections":[]}},
    },{
      plugin: require('../node_modules/gatsby-theme-ghost-dark-mode/gatsby-browser.js'),
      options: {"plugins":[],"defaultModeDark":false,"overrideOS":false},
    },{
      plugin: require('../node_modules/gatsby-theme-ghost-toc/gatsby-browser.js'),
      options: {"plugins":[],"maxDepth":2},
    }]
